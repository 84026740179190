<template>
  <page-content :show-search="is_admin">
    <template #search>
      <table-search
        ref="search"
        v-model="filterValue"
        :fields="search_fields"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <b-tabs lazy>
      <b-tab title="First" @click="tabChange(1)" active>
        <template #default>
          <table-header
            ref="header"
            :title="title"
            :hideTitle="true"
            :button-actions="header_actions"
            @runSearch="runSearch"
            :hide-search="false"
          />

          <table-content
            :per-page="page_condition.pageSize"
            :per-page-dropdown-enabled="true"
            @perPageChange="perPageChange"
            :columns="columns"
            :rows="rows"
            :total-rows="total_rows"
            :current-page="current_page"
            :action-options="table_actions"
            @runTableAction="runTableAction"
            @sortChange="sortChange"
            @pageChange="pageChange"
            :permission="view_permission"
          />
        </template>
      </b-tab>
      <b-tab title="Second" @click="tabChange">
        <template #default>
          <table-header
            ref="header"
            :title="title + '2'"
            :hideTitle="true"
            :button-actions="header_actions"
            @runSearch="runSearch"
            :hide-search="false"
          />

          <table-content
            :per-page="page_condition.pageSize"
            :per-page-dropdown-enabled="true"
            @perPageChange="perPageChange"
            :columns="columns"
            :rows="rows"
            :total-rows="total_rows"
            :current-page="current_page"
            :action-options="table_actions"
            @runTableAction="runTableAction"
            @sortChange="sortChange"
            @pageChange="pageChange"
            :permission="view_permission"
          />
        </template>
      </b-tab>
      <b-tab title="Third" @click="tabChange">
        <p>I'm the third tab</p>
      </b-tab>
      <b-tab title="Disabled" @click="tabChange" disabled>
        <p>I'm a disabled tab!</p>
      </b-tab>
    </b-tabs>

  </page-content>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent.vue'
import TableHeader from '@/components/TableHeader.vue'
import TableSearch from '@/components/TableSearch.vue'
import PageContent from '@/components/PageContent.vue'

export default {
  components: {
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('role'),
      is_admin: common.isAdmin(),
      view_permission: common.checkPermission('Role_List'),
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'role.roleName',
          field: 'roleName'
        },
        {
          label: 'common.action',
          field: 'action',
          width: '200px',
          sortable: false,
        },
      ],
      header_actions: [
        {
          text: 'common.add',
          variant: 'primary',
          fun: this.addData,
          permission: 'Role_Create'
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'Role_View'
        },
        {
          text: 'common.delete',
          variant: 'danger',
          fun: 'deleteData',
          permission: 'Role_Delete'
        }
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'isActive',
          label: 'staff.isActive',
          type: 'select',
          options: [
            {
              text: this.$t('staff.active'),
              value: true
            },
            {
              text: this.$t('staff.passive'),
              value: false
            },
          ],
          show: false
        },
      ],
      filterValue: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        isActive: true
      }
    }
  },
  computed: {
    searchContent() {
      return this.$refs.header.searchContent
    }
  },
  mounted() {
    if (!common.isAdmin()) {
      this.getList()
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      this.getList()
    }
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      const api = '/role/GetRoleList'
      const search = this.getSearchCondition()

      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)

      common.apiGetData(url)
        .then(res => {
          this.total_rows = res.data.total
          this.rows = res.data.roles
        })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    addData: function () {
      this.$router.push({ name: 'role_add' })
    },
    editData: function (data) {
      this.$router.push({
        name: 'role_edit',
        params: { id: common.encrypt(data.roleID) }
      })
    },
    deleteData: function (data) {
      const that = this

      common.showConfirm(this.$t('common.delete_tips') + data.roleName, '', function () {
        common.apiPostData('/role/DeleteRole?roleID=' + data.roleID)
          .then(res => {
            common.showToast(that.$t('common.delete_success'))
            that.getList()
          })

      })
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
      })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    tabChange(c, p) {
      console.log(c, p)
    }
  }
}
</script>
